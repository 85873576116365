import { ProductThemeContext } from '@prishapolicy/shared/ui';
import { useContext, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

/* eslint-disable-next-line */
export interface HeroBackgroundProps {
  backgroundColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backgroundImage?: any;
  padding?: boolean;
  height?: number;
  PurchaseFlowFormRenderer?: () => JSX.Element;
  isUnderconstruction?: boolean;
  negativeMargin?: boolean;
  className?: string;
}

export const HeroBackground = ({ padding = true, ...props }: HeroBackgroundProps) => {
  const formDiv = useRef(null as null | HTMLDivElement);
  const [formDivHeight, setFormDivHeight] = useState<number>(0);
  useEffect(() => {
    const setHeight = () => {
      setFormDivHeight(formDiv.current?.clientHeight || 0);
    };
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);
  const productThemeContext = useContext(ProductThemeContext);

  return (
    <div
      style={{
        paddingBottom: formDivHeight * 0.5,
      }}
    >
      <div
        className={twMerge(
          ` mx-3 lg:mx-7 ${padding && `pt-24 lg:pt-32`} grid place-items-center rounded-b-[40px] bg-${props.backgroundImage ? `${props.backgroundColor}` : props.backgroundColor || productThemeContext?.primaryShade
          } ${!props.PurchaseFlowFormRenderer && padding && 'pb-24 lg:pb-32'} ${props.className}`,
        )}
        style={{
          ...(props.backgroundImage
            ? {
              backgroundImage: `url(${props.backgroundImage})`,
            }
            : {}),

          backgroundSize: 'contain',
        }}
      >
        <div className={`${padding ? 'container' : ''} relative z-[1]`}>
          {props.children}
          <div
            ref={formDiv}
            className="w-full"
            style={{
              marginBottom: -formDivHeight * 0.5,
            }}
          >
            {props.PurchaseFlowFormRenderer && <props.PurchaseFlowFormRenderer />}
          </div>
        </div>
      </div>
    </div>
  );
};
