import { CalendlyButton } from '../../../../../../shared/ui/src/lib/calendly';
import style from './style.module.scss';

const TestimonialContent = () => {
  return (
    <div className="bg-white">
      <div className="container max-w-[600px] text-center xl:text-left">
        <p className="text-primary font-medium uppercase">FROM OUR CLIENTS</p>
        <h2 className="heading-3 mt-3">Built from the ground up over the years.</h2>
        <p className="mt-2">
          Insurance should be more about taking a precaution. So we've designed our services to safeguard and create
          wealth.
        </p>

        <div className="mt-7 flex flex-col justify-center xl:block">
          <CalendlyButton />
        </div>
      </div>
    </div>
  );
};
export const TestimonialMarquee = () => {
  return (
    <>
      <div className="pb-8 lg:hidden">
        <TestimonialContent />
      </div>
      <div className="py-15  overflow-x-hidden bg-white">
        <div className="mx-0 mt-3 flex">
          <div className="w-full px-0 lg:w-1/2">
            <div className={style['slidingBackground']}></div>
          </div>
          <div className="z-10 hidden items-center bg-white px-5 pl-[52px] lg:flex lg:w-1/2">
            <TestimonialContent />
          </div>
        </div>
      </div>
    </>
  );
};
