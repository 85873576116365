import { Button, ButtonProps } from '@prishapolicy/shared/ui';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { PopupModal } from 'react-calendly';
export const calendlyData = {
  url: 'https://calendly.com/prishapolicy/callwithexpert',
};

export type CalendlyContextType = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  calendlyData?: typeof calendlyData;
  rootId?: string;
};
export const CalendlyContext = createContext<CalendlyContextType>(null as unknown as CalendlyContextType);
export const useCalendlyContext = () => useContext(CalendlyContext);

export const CalendlyModal =
  // dynamic(
  //   Promise.resolve(
  () => {
    const ctx = useCalendlyContext();
    return (
      <PopupModal
        url={ctx.calendlyData?.url || calendlyData.url}
        onModalClose={() => ctx.setIsOpen(false)}
        open={ctx.isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        rootElement={document.getElementById(ctx.rootId || '__next')!}
      />
    );
  };
//   ),
//   { ssr: false }
// );

export const CalendlyButton = (props: ButtonProps) => {
  const ctx = useCalendlyContext();
  return (
    <Button
      text={props.text || 'Schedule a call'}
      variant="primary"
      size="large"
      rightIcon="arrowRight"
      {...props}
      onClick={() => ctx.setIsOpen(true)}
    />
  );
};
